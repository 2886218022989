// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-app-page-hook-js": () => import("./../src/app/page-hook.js" /* webpackChunkName: "component---src-app-page-hook-js" */),
  "component---src-app-page-js": () => import("./../src/app/page.js" /* webpackChunkName: "component---src-app-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-salesforce-bkkc-quiz-js": () => import("./../src/salesforce/bkkc-quiz.js" /* webpackChunkName: "component---src-salesforce-bkkc-quiz-js" */),
  "component---src-salesforce-quiz-js": () => import("./../src/salesforce/quiz.js" /* webpackChunkName: "component---src-salesforce-quiz-js" */),
  "component---src-salesforce-wk-2022-js": () => import("./../src/salesforce/wk2022.js" /* webpackChunkName: "component---src-salesforce-wk-2022-js" */)
}

