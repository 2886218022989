module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W4MR5MG","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"promo-betcenter-be","shortenUrlLangs":true,"defaultLang":"en-gb","langs":["de-de","nl-be","fr-be",null,"tr","en-gb"],"previews":false,"pages":[{"type":"Page","match":"/:lang/wk2022","component":"/opt/build/repo/src/salesforce/wk2022.js"},{"type":"Page","match":"/:lang/bkkc","component":"/opt/build/repo/src/salesforce/bkkc-quiz.js"},{"type":"Page","match":"/:lang/quiz","component":"/opt/build/repo/src/salesforce/quiz.js"},{"type":"Page","match":"/:lang/:uid/success","component":"/opt/build/repo/src/app/page.js"},{"type":"Page","match":"/:lang/:uid","component":"/opt/build/repo/src/app/page.js"},{"type":"Page","match":":uid","component":"/opt/build/repo/src/app/page-hook.js"},{"type":"Page","match":"","component":"/opt/build/repo/src/app/page-hook.js"}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
